@import "trix/dist/trix";

// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.
.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }

  .attachment__caption {
    .attachment__link::before {
      content: ' · ';
    }

    .attachment__link {
      a {
        text-decoration: underline;
      }
    }
  }

  li {
    // increase li-margin so that bullets do not get cut off
    margin-left: 1.2em;
  }
}

trix-editor {
  min-height: 20em;
}

trix-toolbar {
  .trix-button-group:not(:first-child) {
    margin-left: 0.75em;
  }
}

action-text-attachment {
  figure {
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    img {
      max-width: 100%;
    }
  }
}

.action-text-form {
  // Display all form inputs in full width when rich editor is used in form
  .form-field__control {
    margin-left: 0px !important;
  }
}

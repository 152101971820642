////////////////////////////////////////////////////////////////////////////////////////////
// Generic styles for digibase MDC apps                                                   //
// Define the following three variables in inlcuding stylesheets to set the theme colors: //
//   --mdc-theme-primary: #f7a600;                                                        //
//   --mdc-theme-primary-darker: #e69900;                                                 //
//   --mdc-theme-secondary: #5bc5f2;                                                      //
////////////////////////////////////////////////////////////////////////////////////////////

@use '@material/snackbar'; // snackbar mixin namespace
@use '@material/linear-progress'; // linear-progress mixin namespace

// NOTE We cannot import via "@use 'material-components-web/material-components-web';" anymore because we need "@use '@material/switch/deprecated/mdc-switch';"
@use '@material/banner/styles' as banner-styles;
@use '@material/button/mdc-button';
@use '@material/card/mdc-card';
@use '@material/checkbox/mdc-checkbox';
@use '@material/chips/deprecated/mdc-chips';
@use '@material/circular-progress/mdc-circular-progress';
// Select's styles must be emitted before datatable's due to equal specificity.
@use '@material/select/mdc-select';
@use '@material/data-table/mdc-data-table';
@use '@material/dialog/mdc-dialog';
@use '@material/drawer/mdc-drawer';
@use '@material/elevation/mdc-elevation';
@use '@material/fab/mdc-fab';
@use '@material/floating-label/mdc-floating-label';
@use '@material/form-field/mdc-form-field';
@use '@material/icon-button/mdc-icon-button';
@use '@material/image-list/mdc-image-list';
@use '@material/layout-grid/mdc-layout-grid';
@use '@material/line-ripple/mdc-line-ripple';
@use '@material/linear-progress/mdc-linear-progress';
@use '@material/list/mdc-list';
@use '@material/menu/mdc-menu';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/notched-outline/mdc-notched-outline';
@use '@material/radio/mdc-radio';
@use '@material/ripple/mdc-ripple';
@use '@material/segmented-button/styles' as segmented-button-styles;
@use '@material/slider/styles' as slider-styles;
@use '@material/snackbar/mdc-snackbar';
@use '@material/switch/deprecated/mdc-switch';
@use '@material/tab/mdc-tab';
@use '@material/tab-bar/mdc-tab-bar';
@use '@material/tab-indicator/mdc-tab-indicator';
@use '@material/tab-scroller/mdc-tab-scroller';
@use '@material/textfield/mdc-text-field';
@use '@material/theme/mdc-theme';
@use '@material/tooltip/styles' as tooltip-styles;
@use '@material/top-app-bar/mdc-top-app-bar';
@use '@material/typography/mdc-typography';

@use "material-datetime-picker/dist/material-datetime-picker";

// Fonts
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('MaterialIcons-Regular.woff2') format('woff2'),
  url('MaterialIcons-Regular.woff') format('woff'),
  url('MaterialIcons-Regular.ttf') format('truetype');
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('roboto-v20-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('roboto-v20-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('roboto-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('roboto-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('roboto-v20-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('roboto-v20-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

// Styles

$layout-xsmall-max: 599px;
$layout-small-min: 600px;
$layout-medium-min: 1024px;

:root {
  --mdc-theme-warning: #f2dede;

  --mdc-top-app-bar-height: 64px;
  @media (max-width: $layout-xsmall-max) {
    --mdc-top-app-bar-height: 56px;
  }

  --hs-bottom-nav-height: 56px;
  --hs-bottom-nav-height-and-inset: calc(var(--hs-bottom-nav-height) + env(safe-area-inset-bottom)); // Account for overlays in viewport like the home bar in newer iOS versions
  --hs-drawer-width: 256px;
  --hs-fab-offset: 15px;
  --hs-max-content-width: 1024px;
}

body {
  background-color: #fafafa;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.hs-hidden {
  display: none !important;
}

.cookies-eu {
  font-family: Roboto,sans-serif;
  font-family: var(--mdc-typography-font-family,Roboto,sans-serif);
  position: fixed;
  z-index: 100;

  .cookies-eu-button-holder {
    .cookies-eu-ok {
      background-color: var(--mdc-theme-primary);
    }

    .cookies-eu-link {
      color: var(--mdc-theme-primary);
    }
  }
}

.circle-icon {
  background-color: #e0e0e0;
  color: var(--mdc-theme-text-secondary-on-light);
}

.avatar {
  border-radius: 50%;
  display: inline-flex;
  height: 40px;
  width: 40px;
}

.icon {
  align-self: flex-start;
  display: inline-flex;
  height: 24px;
  width: 24px;
}

.thumbnail-preview {
  border: 1px solid;
  border-color: #8d8d8d;
  height: 283px;
  width: 200px;
}

.thumbnail-preview-link .material-icons {
  position: absolute;
  top: 140px;
  left: 130px;
  font-size: 144px;
  color: var(--mdc-theme-primary);
  opacity: 0.5;
}

.section-heading {
  // TODO-digibase-home: Get SASS mixins to work and use group-subheader-ink-color($color) to color the heading
  color: var(--mdc-theme-text-secondary-on-background);
  margin-left: 72px;
  margin-bottom: 0px;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-floating-label,
.mdc-select--focused:not(.mdc-select--disabled):not(.mdc-select--invalid) .mdc-floating-label {
  color: var(--mdc-theme-primary); // Luftverbund uses a transparency here, so color would be #f7a600de if we did exactly the same but I see no recognizable difference with that
}

.img-403 {
  max-height: calc(100vh - 200px);
  max-width: 100%;
}

.app-banner {
  max-width: 100%;
}

/////////////////////////////////////
// Top app bar                     //
/////////////////////////////////////
@media screen and (min-width: $layout-medium-min) {
  #app-bar {
    // 'inherit' on smaller viewports would shrink the app bar so that it just fits for its content, leading to a gap to the right. But it is required with the fixed sidebar open to prevent flowing its row out of the viewport.
    width: inherit;
  }
}

.mdc-top-app-bar {
  z-index: 5; // needed to ensure top app bar menu items appear above .hs-scoping-object (has z-index 4)
}

// Adjustments for mobile phones with a notch
.mdc-top-app-bar__row {
  margin-top: env(safe-area-inset-top);
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: calc(var(--mdc-top-app-bar-height) + env(safe-area-inset-top));
}

.hs-top-app-bar--search-form {
  display: none;

  @media screen and (min-width: $layout-small-min) {
    // Make the search form full width but ensure padding to the top app bar actions - for xsmall the search bar would overlap the search icon
    padding-right: 12px;
    width: calc(var(--hs-max-content-width) - 12px);
    // HACK: Constraining max-width to 60% is a workaround for the search bar still overlapping the top app bar actions/search icon at a certain width. Couldn't figure out how to make the search form properly taking up the available space over the main content without overflowing.
    max-width: 60%;

    .hs-top-app-bar--search-field {
      width: 100%;
    }
  }
}

.hs-top-app-bar--search-field {
  background-color: var(--mdc-theme--surface, #FFF);
  border-radius: 28px;
  height: 48px;

  .mdc-notched-outline {
    .mdc-notched-outline__leading {
      border-radius: 28px 0 0 28px;
      width: 28px;
    }

    .mdc-notched-outline__trailing {
      border-radius: 0 28px 28px 0;
      width: 28px;
    }
  }

  .material-icons {
    color: var(--mdc-theme-text-secondary-on-light);
  }
}

.mdc-top-app-bar .mdc-menu .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic {
  margin-right: 8px;
}

/////////////////////////////////////
// Form app bar                    //
/////////////////////////////////////
.hs-top-app-bar--form {
  background: #FFF;
  color: #000;
}

.hs-top-app-bar--form .hs-top-app-bar--form__navigation-icon {
  color: #000;
}

/////////////////////////////////////
// Progressbar                     //
/////////////////////////////////////
.mdc-linear-progress {
  @include linear-progress.bar-color(var(--mdc-theme-primary-darker));

  position: sticky;
  top: var(--mdc-top-app-bar-height);
  z-index: 5; // .hs-scoping-object has 4 and should stay beneath
}

/////////////////////////////////////
// App drawer                      //
/////////////////////////////////////

.mdc-drawer.mdc-drawer--dismissible.hs-drawer--fixed {
  position: fixed;
}

.mdc-drawer .mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic,
.mdc-drawer .mdc-deprecated-list-item--activated {
  color: var(--mdc-theme-primary);
}

.mdc-drawer .mdc-drawer__subtitle {
  // accomodate for app banner
  margin-left: 32px;
}

// Make the last item (imprint link) stick to the bottom
.mdc-drawer nav  {
  // Absolute positioned elements like the imprint link are positioned relative to their closest positioned ancestor, hence set a position value for its container (I don't think the value 'relevant' is key here))
  position: relative;
  // Make sure the nav takes up all space to the bottom when the nav items fit on the screen
  min-height: calc(100% - 56px);
  // Add padding to have spare height for the imprint nav element when items do not fit on the screen
  padding-bottom: 40px;
}

.mdc-drawer nav > :last-child {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

// Adjustments for mobile phones with a notch
.mdc-drawer {
  margin-top: env(safe-area-inset-top);
  height: calc(100% - env(safe-area-inset-top));
}

/////////////////////////////////////
// App content                     //
/////////////////////////////////////
.mdc-drawer-app-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hs-drawer--fixed ~ .mdc-drawer-app-content,
.hs-drawer--fixed ~ .hs-bottom-nav {
  margin-left: var(--hs-drawer-width);
  width: calc(100% - var(--hs-drawer-width));

  .hs-top-app-bar--search-form {
    position: relative;
    // The -12px attribute to the 12px padding from .mdc-top-app-bar__section and ensure the cross icon to be aligned with the list item icons
    left: max(0px, calc(50vw - 0.5 * (var(--hs-max-content-width) + var(--hs-drawer-width)) - 12px));
  }
}

#main-content {
  background-color: var(--mdc-theme-background);
  width: 100%;
  max-width: var(--hs-max-content-width);
  min-height: calc(100vh - var(--mdc-top-app-bar-height));
}

#main-content.hs-bottom-nav--fixed-adjust {
  min-height: calc(100vh - var(--mdc-top-app-bar-height) - var(--hs-bottom-nav-height-and-inset));
}

/////////////////////////////////////
// List and list items             //
/////////////////////////////////////
// We wrap list items linking somewhere in a tags but we want no a specific (default) styling.
.mdc-deprecated-list a {
  color: inherit;
  text-decoration: none;
}
// Links in form descriptions should be recognizable though.
.mdc-deprecated-list .mdc-deprecated-list-item__secondary-text a {
  color: var(--mdc-theme-primary);
  text-decoration: none;
}

// List items that do not contain links should be selectable
.mdc-deprecated-list > li {
  cursor: text !important;
}

.mdc-deprecated-list-item__meta {
  text-align: right;

  img {
    height: 24px;
  }
}

.mdc-deprecated-list-item__meta--top-aligned {
  align-self: flex-start;
  // TODO-digibase-home: Is there a way to express the top padding relatively to the list, list item or sibling content (one line, two line text)?
  //                     For two-line lists, material defines 72px height for mdc-deprecated-list-item__text: https://github.com/material-components/material-components-web-components/blob/fa0ad66a0e51b615b4b917514c50893fc770e17b/packages/list/mwc-list-item.scss#L227
  padding-top: 16px;
}

// TODO-digibase-home: Luftverband uses a 'action' class for a span within the text element to make the actin text grey. It also assigns an action-item class to the containing list item but there is no style defined for it. Resolve with @tpleyer how to handle this. If there is no reason against it, it seems more pragmatic to define it like here.
.mdc-deprecated-list-item.action-item {
  color: var(--mdc-theme-text-secondary-on-background);
}

.mdc-deprecated-list.detail {
  .mdc-deprecated-list-item {
    height: 100%;
    padding-bottom: 12px;
  }

  .mdc-deprecated-list-item__text {
    align-self: unset;
  }

  .mdc-deprecated-list-item__secondary-text {
    white-space: normal;
    line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  }

  .mdc-deprecated-list-item__secondary-text.empty::before {
    height: 0px;
  }

  .mdc-deprecated-list-item.secondary-only {
    margin-top: 16px;

    .mdc-deprecated-list-item__graphic {
      margin-top: 0px;
    }

    .mdc-deprecated-list-item__secondary-text::before {
      height: 0px;
    }
  }
}

// distinguishes the following cases:
// Items with secondary text only at the beginning of a list sequence (e.g. at the top of the page) -> should have a margin
// secondary-only-items in sequence -> should not have a margin
// secondary-only-lists following other lists -> should not have a margin
li.secondary-only + li.secondary-only {
  margin-top: 0px !important;
}

ul + ul.detail {
  .mdc-deprecated-list-item.secondary-only {
    margin-top: 0px !important;
  }
}

div.trix-content {
  display: inline-block;
}

.mdc-deprecated-list-item a.mdc-button--outlined {
  width: 100%;
}

// Unread system messages
.mdc-deprecated-list-item.unopened {
  .circle-icon {
    background-color: var(--mdc-theme-primary);
    color: var(--mdc-theme-surface);
  }
}

.mdc-deprecated-list-item.highlight-green {
  .mdc-deprecated-list-item__graphic.material-icons {
    color: rgba(30, 160, 50, 0.6)
  }
}

.mdc-deprecated-list-item.highlight-red {
  .mdc-deprecated-list-item__graphic.material-icons {
    color: rgba(170, 15, 15, 0.6)
  }
}

// do not stack paddings for list items within a layout grid
.mdc-layout-grid {
  .mdc-deprecated-list-item {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/////////////////////////////////////
// Scoped lists                    //
/////////////////////////////////////

.hs-scoping-object {
  position: sticky;
  top: var(--mdc-top-app-bar-height);
  z-index: 4;
  width: 100%;
  max-width: var(--hs-max-content-width);
  background-color: white;
  background-color: var(--mdc-theme-background);
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.hs-scoping-object--fixed-height {
  max-height: 250px;
  overflow-y: scroll;
}

.hs-scoping-object-scrolled {
  border-bottom-width: 0px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  transition: box-shadow 200ms linear;
}

/////////////////////////////////////
// Forms and form fields           //
/////////////////////////////////////
.form .hs-form-subheader {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 56px;
}

// NOTE: Styles below taken as is from connect, except for additional selector to make .mdc-select full width (has `display: inline-flex;` in our app/style, Luftverbund does not have it!??).
.form .form-field {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  padding-bottom: 19px;
}

.form .form-field .form-field__graphic {
  margin-right: 32px;
  width: 24px;
  height: 24px;
  padding-top: 16px;
  color: var(--mdc-theme-text-icon-on-background);
}

.form .form-field .form-field__graphic .mdc-icon-button {
    color: var(--mdc-theme-text-icon-on-background);
    --mdc-theme-primary: var(--mdc-theme-text-icon-on-background);
    --mdc-theme-secondary: var(--mdc-theme-text-icon-on-background);
}
.form .form-field .form-field__control:first-child {
    margin-left: 56px;
}
.form .form-field .form-field__control,
.form .form-field .form-field__control .mdc-form-field {
    justify-content: space-between;
    width: 100%;
}
.form .form-field .form-field__control .mdc-button {
  width: 100%;
}
.form .form-field .form-field__control .mdc-text-field-helper-line,
.form .form-field .form-field__control .mdc-select-helper-text ,
.form .form-field .form-field__control .mdc-select-helper-line {
    margin-bottom: -19px;
}
.form .form-field .form-field__control.form-field__control--group .mdc-text-field-helper-line {
    margin-bottom: 0px;
}
// NOTE: The next line is required to make our selects full width, they are not because of their display-property which is different from Luftverbund
.form .form-field .mdc-select,
.form .form-field .mdc-select .mdc-select__anchor,
.form .form-field .mdc-select .mdc-menu,
.form .form-field .mdc-text-field {
    width: 100%;
    background-color: var(--mdc-theme-background);
}
.form .form-field .mdc-form-field {
    height: 56px;
}
.form .form-field .mdc-form-field--align-end > label {
    margin-left: 12px;
}

.mdc-switch, .mdc-checkbox, .mdc-radio {
  /* override the secondary color with the primary color to make activated switches, checkboxes, and radio buttons appear in digibase home orange */
  --mdc-theme-secondary: #f7a600; // Fallback for older browser that don't support the var-Syntax
  --mdc-theme-secondary: var(--mdc-theme-primary, #f7a600);
}

.hs-file-input {
  /* places a span displaying the file name over the original file input. */
  position: absolute;
  top: 30px;
}

// The class will be removed by JavaScript on clicking the parent .mdc-form-field
.hs-checkbox--invalid {
  color: var(--mdc-theme-error);
}

/////////////////////////////////////
// DateTimePicker                  //
/////////////////////////////////////

// mdc-top-app-bar has a z-index being set to 5, hence we need to elevate the scrim and datepicker for them to be shown above the bar
.c-scrim {
  z-index: 6;
}

.c-datepicker {
  font-family: Roboto,sans-serif;
  font-family: var(--mdc-typography-font-family,Roboto,sans-serif);
  z-index: 7;
}
.c-datepicker__header-date {
  background-color: var(--mdc-theme-primary);
}
.c-datepicker__header-day {
  background-color: var(--mdc-theme-primary-darker);
}

/////////////////////////////////////
// Login form                      //
/////////////////////////////////////
.login-form .mdc-typography--headline4 {
    padding-top: var(--mdc-layout-grid-margin-phone, 16px);
    padding-bottom: var(--mdc-layout-grid-margin-phone, 16px);

    @media (min-width: $layout-small-min) {
      text-align: center;
    }

    .app-icon {
      vertical-align: middle;
      width: 48px;
    }
}

.login-form .mdc-typography--headline5 {
    padding-top: var(--mdc-layout-grid-margin-phone, 16px);
    padding-bottom: var(--mdc-layout-grid-margin-phone, 16px);
}

// Override default left margin from mdc web to make inputs full width
.login-form .form .form-field .form-field__control:first-child {
  margin-left: 0px;
}

.login-form .mdc-button {
    width: 100%
}

.login-form form {
  margin-bottom: 8px;
}

/////////////////////////////////////
// Smart Viewer                    //
/////////////////////////////////////
.smart-post-gimmick {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    max-width: 512px;
    height: auto;
  }

  .mdc-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .cleared-width {
    width: auto !important;
  }
}

/////////////////////////////////////
// Legal notice footer             //
/////////////////////////////////////

.hs-legal-notice-footer--adjust {
  // keeps footer stick to the bottom of the page, even if content is shorter
  // 56px app bar + 152px footer = 208px
  min-height: calc(100vh - 208px)
}

.hs-legal-notice-footer {
  margin-bottom: calc(-1 * var(--hs-bottom-nav-height-and-inset)); // counter the bottom-nav adjust done by the layout since there is no bottom nav on the login screen
  background-color: white;
  background-color: var(--mdc-theme-background);
  width: 100%;

  .hs-legal-notice-footer-content {
    text-align: center;
    color: var(--mdc-theme-text-secondary-on-background);
    font-size: var(--mdc-typography-body2-font-size, 0.875rem);
    font-weight: var(--mdc-typography-body2-font-weight, 400);
    line-height: var(--mdc-typography-body2-line-height, 1.25rem);
    padding-left: 16px;
    padding-right: 16px;
  }

  a {
    color: var(--mdc-theme-primary);
    text-decoration: none;
  }
}

.hs-bottom-nav--fixed-adjust .hs-legal-notice-footer {
  margin-bottom: 0px; // override the adjust from above if there is a bottom nav as in the imprint
}

/////////////////////////////////////
// Bottom Navigation               //
/////////////////////////////////////
.hs-bottom-nav {
  display: block;
  height: var(--hs-bottom-nav-height-and-inset);
  background-color: white;
  background-color: var(--mdc-theme-background);

  // Override mdc's default min-widht of 90px since it causes buttons to overflow to the right on smaller screens (e.g. width 414px)
  .mdc-tab {
    min-width: 50px;
  }
}

.hs-bottom-nav--fixed-adjust {
  margin-bottom: var(--hs-bottom-nav-height-and-inset);
}

.hs-bottom-nav--elevated {
  box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.16), 0px -3px 8px 0px rgba(0, 0, 0, 0.11), 0px 0px 15px 0px rgba(0, 0, 0, 0.09);
}

.hs-bottom-nav--fixed {
  position: fixed;
  bottom: 0px;
}

.hs-tab--stacked {
  height: var(--hs-bottom-nav-height);
  padding-left: 10px;
  padding-right: 10px;
  width: 20%;
  font-size: 0.6rem;
}

.hs-unread-badge {
  width: 8px;
  height: 8px;
  background-color: var(--mdc-theme-secondary);
  position: absolute;
  border-radius: 50%;
  margin-left: 20px;
}

/////////////////////////////////////
// Snackbar                        //
/////////////////////////////////////

// Adjust snackbar by bottom nav height
.mdc-snackbar {
  bottom: var(--hs-bottom-nav-height-and-inset);
}

// on wider screens, extend the snackbars margin
@media (min-width: snackbar.$mobile-breakpoint) {
  .mdc-snackbar {
    @include snackbar.viewport-margin(snackbar.$viewport-margin-wide);
  }
}

// on mobile, stack buttons (if any) below the snackbar text
@media (max-width: snackbar.$mobile-breakpoint) {
  .mdc-snackbar {
    @include snackbar.layout-stacked;
  }
}

/////////////////////////////////////
// Banner                          //
/////////////////////////////////////

.mdc-banner__fixed {
  max-width: var(--hs-max-content-width);
}

/////////////////////////////////////
// FAB                             //
/////////////////////////////////////
.mdc-fab {
  position: fixed;
  bottom: var(--hs-fab-offset);
  right: max(var(--hs-fab-offset), calc(var(--hs-fab-offset) + (50vw - 0.5 * (var(--hs-drawer-width) + var(--hs-max-content-width)))));
  z-index: 7; // .mdc-snackbar has 8 and should cover FABs
}

.hs-bottom-nav--fixed-adjust .mdc-fab {
  bottom: calc(var(--hs-bottom-nav-height-and-inset) + 15px);
}

/////////////////////////////////////
// Error pages                     //
/////////////////////////////////////
.hs-http-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


/////////////////////////////////////
// digibase Pay                    //
/////////////////////////////////////
.StripeElement {
  background-color: white;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#payment_method_info:not(.sofort) {
  div#sofort-payment {
    display: none;
  }
}

#payment_method_info:not(.card) {
  div#card-payment {
    display: none;
  }
}

#payment_errors_banner.invisible {
  display: none;
}
